<template>

  <!-- Start Page Title Area -->
  <div class="page-title-area page-title-area-smaller" v-if="loadingAny">
    <div class="container">
      <div class="page-title-content">
        <h2>Loading session</h2>
        <div><pulse-loader :loading="loadingAny" color="#E21F36" size="10px"></pulse-loader></div>
      </div>
    </div>
  </div>
  <div class="page-title-area page-title-area-smaller" v-if="!loadingAny">
    <div class="container">
      <div class="page-title-content">
        <h2>{{sectionCatItem.title}}</h2>
        <h4 class="active">Training Session</h4>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->




  <div class="page-content trainingpage">


    <!-- buttons block start -->
    <h6 class="mb-0 text-uppercase">{{ session.questionsAmount }} questions</h6>


    <hr>

    <!-- buttons row -->
    <div v-if="Object.keys(questionIds).length !== 0 && selectedQuestionId !== undefined" class="questionsNavButtons" id="questionsNavButtons">
      <button type="button" v-for="(questionId,index) in questionIds" v-bind:key="questionId" v-on:click="selectQuestion(questionId)" :class="'ses-pos-button'+selectedQuestionIsCurrentClassString(questionId)+ ' ses-pos-button-state-'+answerStateString(questionId)" :title="answerStateString(questionId)">{{index+1}}</button>
    </div>
    <!-- buttons block end -->

    <!-- question-answer-block -->
    <div v-if="selectedQuestionId !== null && answersByQuestion !== null">
      <TrainingQuestionAnswer v-model:answer="answer" :questionId="selectedQuestionId" v-on:verified="onAnswerVerified" v-model:answerIds="answerIds" :sectionCatItem="sectionCatItem"/>
    </div>

  </div>



  <div class="blockQnav">
      <div class="qnav-button-row">
        <div class="qnav-button-row-block qnav-button-row-block-summary">
          <button @click="goToTraining" class="qnav-button button-summary"><i class="fa-solid fa-chart-simple-horizontal biconO"></i><div class="btextO">overview</div></button>
        </div>
        <div class="qnav-button-row-block qnav-button-row-block-nav">
          <button @click="gotoLast" :disabled="this.questionPointer === 0" class="qnav-button button-prev"><i class="fa-regular fa-chevron-left biconL"></i><div class="btext">previous</div></button>
          <button @click="gotoNext" :disabled="this.questionPointer === (this.questionIds.length-1)" class="qnav-button button-next"><div class="btext">next</div><i class="fa-regular fa-chevron-right biconR"></i></button>
        </div>
      </div>

  </div>


</template>


<script>
import  { initAuthUser, changeOnAuthEvent } from '@/js/authutil'
import {Auth} from "aws-amplify";
import {AuthState} from "@aws-amplify/ui-components";
import axios from "axios";
import TrainingQuestionAnswer from '@/views/fragments/TrainingQuestionAnswer.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'



// it may look strange the answerIds are handled here and not in TrainingQuestion.
// The reason is, the overview displays a list of questions and needs all answers
// already sent to show the state like wrong/correct/open.
// Therefore it also loads all sent answers at once.
export default {
  components: {
    TrainingQuestionAnswer,
    PulseLoader
  },


  data() {
    return {
      sectionCatItem: {},
      session: {},
      questionIds: [],
      selectedQuestionId: null,
      answers: [],
      answersByQuestion: {},
      answerIdsWatchUsed: false,
      sectionCat: '',
      answerIds: [],
      answer: null,
      questionPointer: 0,
      answerIdsChanged: false,

      loadingSession:false,
      loadingSectionCat:false,
      loadingAnswers:false,
    }
  },

  watch: {

    answerIds: {
      // This will let Vue know to look inside the array
      deep: true,


      // watching for answerIds change, but skip first time, as it is changed on load of answers from backend
      handler(newVal){
        console.log('answerid value changed - parent')

        if (!this.answerIdsWatchUsed){
          this.answerIdsWatchUsed = true
          return
        }

        // update the real answer object with new ids
        this.answersByQuestion[this.selectedQuestionId].answerIds = this.answerIds

        console.log(`Value changed to: ${newVal}`);
        this.answerIdsChanged = true

        this.sendNewSelectedAnswerIds()
      }

    },


  },

  created() {
    console.log('created()')
    // excluded functions

    if (this.userSignedIn){
      this.loadData()
    }else {
      initAuthUser(this, Auth, AuthState)
      changeOnAuthEvent(this, this.onAuthComponentProcessed) // INIT process on change (late login)
    }
  },



  updated(){
    console.log('updated()')
  },


  computed: {
    userSignedIn(){
      return this.$store.getters.userSignedIn
    },

    loadingAny(){
      if (this.loadingSession || this.loadingSectionCat || this.loadingAnswers){
        return true
      }else{
        return false
      }

    }

  },



  methods: {

    // loads the content or redirects if user is not signedin.
    // Must be done AFTER amplify-authenticator has been loaded
    // and updated to know if user is signedin or not.
    onAuthComponentProcessed: function () {
      if (this.$route.name !== 'TrainingSession') {
        return
      } // skip if page does not fit, as it keeps this function in Auth globally

      if (!this.userSignedIn) {
        console.log('user not signed in, not allowed to be on this page - redirect!')
        this.$router.push('/login')
      } else {
        this.loadData()
      }

    },


    loadData: function () {
      if (this.loadDone === true) {
        return
      } // skip if already done
      this.loadDone = true

      this.sessionId = this.$route.params.sessionId
      console.log("Section cat opened: %s", this.sectionCat)

      this.loadSession()
    },

    loadSession: function () {
      axios.defaults.headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken
      }

      this.loadingSession = true

      // load sessions - with question ids
      const url_ses = process.env.VUE_APP_URL_GET_TRAINING_SESSION + '/' + this.sessionId
      console.log("GET: " + url_ses)
      axios.get(url_ses, {cache: true}).then(
          res => {

            this.loadingSession = false

            console.log('session loaded')
            this.session = res.data.payload.item
            console.log(this.session)
            this.sectionCat = this.session.sectionCat
            this.questionIds = this.session['questions']

            this.loadSectionCat()
            this.loadAnswers()
          }
      ).catch(error => console.log(error));
    },


    loadAnswers: function () {
      console.log('before request for: loadAnswers')

      this.loadingAnswers = true

      let headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken,
      }

      // load answers
      const url_answers = process.env.VUE_APP_URL_GET_TRAINING_ANSWERS + '/' + this.sessionId + '/' + this.sectionCat
      //const url_answers = 'http://localhost:3001/tt/answers/00000-00000-00000-00000-00000/linux/basics'
      console.log("GET: " + url_answers)
      axios.get(url_answers, {headers: headers}).then(
          res => {
            this.loadingAnswers = false
            this.answers = res.data.payload.items
            this.calcAnswersByQuestion()
            this.selectQuestion(this.questionIds[this.questionPointer])
          }
      ).catch(error => {
        console.log('Axios request error', error);
      })
    },


    // post answer to an answersession with url path like:
    //   /00000-00000-00000-00000/linux/basics/network/1
    // body content:
    //    correct: true|false
    //    answerIds: [1,2]
    sendNewSelectedAnswerIds: function () {
      axios.defaults.headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken
      }

      const url_answer = process.env.VUE_APP_URL_POST_TRAINING_ANSWER + '/' + this.sessionId + '/' + this.selectedQuestionId
      console.log("POST: " + url_answer)

      const payload = {
        answerIds: this.answerIds,
        correct: this.answersByQuestion[this.selectedQuestionId].correct,
        closed: this.answersByQuestion[this.selectedQuestionId].closed,
      }

      console.log('sending answer update, payload: ', payload)
      axios.post(url_answer, payload).then(
          res => {
            console.log('answer POST response received')
            console.log(res)
          }
      ).catch(error => console.log(error));
    },


    // post answer to an answersession with url path like:
    //   /00000-00000-00000-00000/linux/basics/network/1
    // body content:
    //    correct: true|false
    //    answerIds: [1,2]
    //    final: true
    sendNewSelectedAnswerIds_verified: function () {
      axios.defaults.headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken
      }

      const url_answer = process.env.VUE_APP_URL_POST_TRAINING_ANSWER + '/' + this.sessionId + '/' + this.selectedQuestionId
      console.log("POST: " + url_answer)

      const payload = {
        answerIds: this.answerIds,
        correct: this.answersByQuestion[this.selectedQuestionId].correct,
        closed: this.answersByQuestion[this.selectedQuestionId].closed,
        final: true
      }

      console.log('sending answer update, payload (final): ', payload)
      axios.post(url_answer, payload).then(
          res => {
            console.log('answer POST response received')
            console.log(res)
          }
      ).catch(error => console.log(error));
    },


    loadSectionCat: function () {
      // load training meta information

      this.loadingSectionCat = true

      const url_cat = process.env.VUE_APP_URL_GET_SUBJECTDETAILS + '/' + this.sectionCat
      console.log("GET: " + url_cat)
      axios.get(url_cat, {cache: true}).then(
          res => {
            this.loadingSectionCat = false
            this.sectionCatItem = res.data.payload.item
          }
      ).catch(error => console.log(error));
    },

    calcAnswersByQuestion: function () {
      console.log('init answersByQuestion with known questions to ensure field answerIds')
      this.questionIds.forEach(questionId => {
        this.answersByQuestion[questionId] = {answerIds: []} // add empty array for questions with no answer yet
      })

      console.log('converting answers array to hashmapped by question')
      this.answers.forEach(answer => {
        const questionPath = answer.sessionQuestionPath.replace(this.sessionId + '/', '');
        console.log('add to map: %s', questionPath)

        this.answersByQuestion[questionPath] = answer
      });
    },

    isAnswerCorrect: function (questionId) {
      let res = false;
      const answer = this.answersByQuestion[questionId]
      if ((answer !== undefined) && ('correct' in answer) && (answer['correct'] === true)) {
        res = true
      }
      return res
    },

    isAnswerClosed: function (questionId) {
      let res = false;
      const answer = this.answersByQuestion[questionId]
      if ((answer !== undefined) && ('closed' in answer) && (answer['closed'] === true)) {
        res = true
      }
      return res
    },


    answerStateString: function (questionId) {
      let res = 'open'

      if (this.isAnswerClosed(questionId)) {
        if (this.isAnswerCorrect(questionId)) {
          res = 'correct'
        } else {
          res = 'wrong'
        }
      } else {
        if (this.answersByQuestion[questionId].answerIds.length != 0) {
          res = 'answered'
        }
      }

      return res
    },

    selectedQuestionIsCurrentClassString: function (questionId) {
      let res = ''

      if (this.selectedQuestionId === questionId) {
        res = '-current'
      }
      return res
    },

    selectQuestion: function (questionId) {

      // if (this.answerIdsChanged){
      //   console.log('answerids changed, going to save it')
      //   this.sendNewSelectedAnswerIds()
      // }else{
      //   console.log('answerids not changed')
      // }

      console.log('selected question: %s', questionId)
      this.selectedQuestionId = questionId
      this.answerIdsWatchUsed = false

      this.answerIds = this.answersByQuestion[this.selectedQuestionId].answerIds
      this.answer = this.answersByQuestion[this.selectedQuestionId]
      this.answerIdsChanged = false

      // rewrite pointer - if jumped into a question directly
      let idx = 0
      this.questionIds.forEach((id) => {
        if (id === this.selectedQuestionId) {
          this.questionPointer = idx
        }
        idx++;
      })
    },

    scrollToQuestion: function(){
      console.log("scrollToQuestion");

      this.$nextTick(() => {
        var nameNavigator = window.navigator.userAgent;
        console.log("Navigator: " + nameNavigator);
        var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(nameNavigator);

        var element;
        this.$nextTick(() => {
          var offset = -124; // The additional pixels you want to scroll down

          element = document.getElementById('questionsNavButtons');
          if (isMobile) {
            console.log("scroll for mobile screen");
            offset = 93;
          } else {
            console.log("scroll for standard screen");
          }

          console.log("element: " + element);
          console.log("offset: "+offset);

          if (element) {
            var elementPosition = element.getBoundingClientRect().top + window.scrollY + offset;

            window.scrollTo({
              top: elementPosition,
              behavior: 'smooth'
            });
          } else {
            console.log("Element not found");
          }
        });

        console.log("isMobile:" + isMobile);

      });


    },

    // this is a strange hack. For some reason the normal router-link
    // changes url but does not load the content.
    goToTraining() {
      this.$router.push('/training/' + this.sectionCat).then(() => {
        console.log('Updated route', this.$route)
        this.$nextTick(() => {
          window.location.reload()
        });
      });
    },


    // received notification from TraininQuestion if user click verify
    onAnswerVerified(correctValue) {
      console.log('Parent received answer correct state: %s', correctValue)
      const answer = this.answersByQuestion[this.selectedQuestionId]
      answer.correct = correctValue
      answer.closed = true

      this.sendNewSelectedAnswerIds_verified()
    },

    gotoNext() {
      console.log('goto next question');
      this.questionPointer++;
      const selectQuestionId = this.questionIds[this.questionPointer];
      console.log('select question id: %s', selectQuestionId);
      this.selectQuestion(selectQuestionId);
      this.scrollToQuestion();
      return true;
    },
    gotoLast() {
      console.log('goto next question');
      this.questionPointer--;
      const selectQuestionId = this.questionIds[this.questionPointer];
      console.log('select question id: %s', selectQuestionId);
      this.selectQuestion(selectQuestionId);
      // this.scrollToQuestion();
    },

  }

};



</script>