<template>

  <div v-if="loadingQuestion" class="loadingQuestion">
      <pulse-loader :loading="loadingQuestion" color="#9CC291" size="20px"></pulse-loader>
  </div>


  <div v-if="!loadingQuestion">

    <div class="question-meta">

        <div class="question-meta-keyvalue">
          <div class="text-uppercase">Category</div>
          <div class="question-meta-keyvalue-value">{{categoryHumanReadable}}</div>
        </div>

        <div class="question-meta-keyvalue">
          <div class="text-uppercase">Difficulty</div>
          <div class="question-meta-keyvalue-value">{{difficultyHumanReadable}}</div>
        </div>

    </div>


    <hr>

    <!-- question -->
    <div class="blockQuestion" id="blockQuestion">
      <div v-for="questionSegment in questionItem.question" v-bind:key="questionSegment" class="segmentBlockQuestion">
        <RenderSegmentContent v-bind:contentSegment="questionSegment" />
      </div>
    </div>


    <br/>
    <h6 class="mb-0 text-uppercase">Select your answer</h6>
    <hr>

    <div class="blockAnswers">

      <div class="row answerRow" :class="'answer-row-'+questionItemRightAnswerString(index+1)" v-for="(answerBlock,index) in questionItem.answers" v-bind:key="answerBlock" @click="selectAnswer(index)">
        <div class="answer-checkandletter-area">
          <!-- The letter A/B/C -->
          <div class="answerLetter">{{answerLetters[index]}}</div>

          <!-- the checkbox -->
          <div v-if="selectionType == 'multiple'" class="answer-checkbox">
            <input v-if="!this.localAnswer.closed" type="checkbox" :id="answerIdByIndex(index)" :value="index+1" v-model="localAnswerIds"  class="answerCheckbox" @change="onAnswerChanged">
            <input v-if="this.localAnswer.closed" type="checkbox" :id="answerIdByIndex(index)" :value="index+1" v-model="localAnswerIds"  class="answerCheckbox" onclick="return false">
          </div>
          <div v-else class="answer-checkbox">
            <input v-if="!this.localAnswer.closed" type="radio" name="answergroup" :id="answerIdByIndex(index)" :value="index+1" v-model="localAnswerIds[0]"  class="answerRadio" @change="onAnswerChanged">
            <input v-if="this.localAnswer.closed" type="radio" name="answergroup" :id="answerIdByIndex(index)" :value="index+1" v-model="localAnswerIds[0]"  class="answerRadio" onclick="return false">
          </div>

        </div>
        <!-- answer -->
        <div class="col-8 answer-content-area">
          <div v-for="answerSegment in answerBlock.content" v-bind:key="answerSegment" class="answer-content-block">
            <RenderSegmentContent v-bind:contentSegment="answerSegment" />
          </div>
        </div>

        <!-- verification symbol -->
        <div class="answer-verification-icon">
          <i v-if="questionItemCorrectStateString(index+1).includes('correct')" class="fal fa-check-circle answer-check-symbol-correct" title="Correct answer"></i>
          <i v-if="questionItemCorrectStateString(index+1).includes('wrong')" class="fal fa-times-circle answer-check-symbol-wrong" title="This is not correcty answered"></i>
          <i v-if="questionItemCorrectStateString(index+1).includes('notverified')" class="fal fa-question-circle answer-check-symbol-notverified" title="Select your answer and press verify to see if it was correct"></i>
        </div>
      </div>

    </div>

    <!-- verify -->
    <div v-if="!localAnswer.closed">
      <br/>
      <h6 class="mb-0 text-uppercase">Verification</h6>
      <hr>

      <div class="row">
        <div class="col-4">
          <button @click="verifyAnswer" :disabled="this.localAnswer.closed"  class="default-btn">verify</button>
        </div>
        <div class="col-8">
          If you finally answered the question, you can verify it to see if it is correct. That also locks the answers.
        </div>
      </div>


    </div>

    <!-- Result -->
    <div v-if="localAnswer.closed">
      <br/>
      <h6 class="mb-0 text-uppercase">Result</h6>
      <hr>

      <div class="blockResult">
          <div class="answercheckedicon">
            <i v-if="isAnswerCorrect" class="fal fa-check-circle answer-check-symbol-correct"></i>
            <i v-if="!isAnswerCorrect" class="fal fa-times-circle answer-check-symbol-wrong"></i>
          </div>
          <div class="blockResultText">
            <div v-if="isAnswerCorrect">Congratulations! You answered it correctly.</div>
            <div v-else>Unfortunately wrong. Correct answer is: <div style="display: inline-block;" v-for="number in this.correctAnswerIds" v-bind:key="number">{{answerLetters[number-1]}}&nbsp;</div></div>
            <div>&nbsp;</div>
            <div v-if="correctAnswersInPercent == 0">You did not select any correct answer.</div>
            <div v-else>{{correctAnswersInPercent}}% of your selection is correct.</div>
          </div>
      </div>

    </div>

    <!-- explanation -->
    <div v-if="localAnswer.closed && questionItem.explanation.length > 0">
      <br/>
      <h6 class="mb-0 text-uppercase">Explanation</h6>
      <hr>

      <div class="blockExplanation">
        <div v-for="explanationSegment in questionItem.explanation" v-bind:key="explanationSegment" class="segmentBlockQuestion">
          <RenderSegmentContent v-bind:contentSegment="explanationSegment" v-bind:correctAnswerIds="correctAnswerIds" />
        </div>
      </div>
    </div>

  </div>






</template>



<script>

import axios from "axios";
import {areIntArraysEqual} from '@/js/arrayutil'
import RenderSegmentContent from '@/views/fragments/RenderSegmentContent.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {changeOnAuthEvent, initAuthUser} from "@/js/authutil";
import {Auth} from "aws-amplify";
import {AuthState} from "@aws-amplify/ui-components";

export default {


  components: {
    RenderSegmentContent,
    PulseLoader
  },

  props: ['questionId','answerIds','answer','sectionCatItem'],

  emits:['update:answerIds','verified'],


  data() {
    return {
      questionItem: { explanation: [] },
      answerLetters: ['A','B','C','D','E','F','G','H'],
      selectedAnswers: [],
      localAnswerIds: this.answerIds,
      correctAnswerIds: [],
      localAnswer: this.answer,
      cacheQuestions: {},
      answeredCorrectly: false,
      loadingQuestion: false
    }
  },

  created() {
    console.log(this.questionId)
  },

  mounted(){

    // but also check if already signed in
    if (this.userSignedIn){
      this.loadQuestion()
    }else{
      initAuthUser(this, Auth, AuthState)
      changeOnAuthEvent(this, this.onAuthComponentProcessed) // INIT process on change (late login)
    }

  },

  watch: {
    // watching for questionId change, but skip first time, as it is changed on load of answers from backend
    questionId() {
      console.log('TrainingQuestion monitored a change of questionId to: %s',this.questionId)
      this.loadQuestion()
    },
    // if answer changed in parent
    answer() {
      this.localAnswer = this.answer
      this.localAnswerIds = this.answerIds
    }
  },

  methods: {


    // loads the content or redirectsif user is not signedin.
    // Must be done AFTER amplify-authenticator has been loaded
    // and updated to know if user is signedin or not.
    onAuthComponentProcessed: function(){
      if ( this.$route.name !== 'TrainingQuestionAnswer'){return} // skip if page does not fit, as it keeps this function in Auth globally

      if (! this.userSignedIn){
        console.log('user not signed in, not allowed to be on this page - redirect!')
        this.$router.push('/login')
      }else{
        this.loadQuestion()
      }

    },


    // loads the question
    loadQuestion: function() {
      console.log("loading question: %s", this.questionId)

      axios.defaults.headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken
      }

      console.log("access token: %s", this.$store.getters.userAccessToken)

      // is it cached?
      if (this.cacheQuestions[this.questionId] != null){
        console.log('CACHE READ for question: %s', this.questionId)
        this.questionItem=this.cacheQuestions[this.questionId].data.payload.item
        this.correctAnswerIds = this.getArrayOfCorrectAnswerIdsFromQuestionItem()
        return
      }

      this.loadingQuestion = true

      const url_question = process.env.VUE_APP_URL_GET_TRAINING_QUESTION+'/'+this.questionId
      console.log("GET: "+url_question)
      axios.get(url_question, { cache: true }).then(
          res=> {
            this.loadingQuestion = false
            console.log(res)
            this.questionItem=res.data.payload.item
            this.correctAnswerIds = this.getArrayOfCorrectAnswerIdsFromQuestionItem()

            this.cacheQuestions[this.questionId] = res
          }
      ).catch(error => console.log(error));
    },


    // - disable checkboxes
    // - shows explanation
    // - verifies if answer is correct/wrong
    // - mark selected answers (checkboxes) as wrong/correct
    // - closes question
    // - send POST to close question (done by parent) - emitting message
    verifyAnswer: function(){
      console.log('answer verifcation')

      // close question - also disables the button / checkboxes
      this.localAnswer.closed = true

      // notify parent
      this.$emit('verified', this.isAnswerCorrect)
    },

    getArrayOfCorrectAnswerIdsFromQuestionItem: function() {
      let correctAnswersIds = []
      this.questionItem.answers.forEach(answer => {
        //if ('correct' in answer && answer["correct"] === "true"){ // for BOOL as text
        if ('correct' in answer && answer.correct){ // for BOOL as boolean
          correctAnswersIds.push(answer.id)
        }
      })
      correctAnswersIds = correctAnswersIds.sort(function (a, b) {  return a - b;  }); // int sort

      return correctAnswersIds
    },

    // checks if an item is correct/wrong and
    // returns a string, which can be used in CSS
    // to mark answers as correct/wrong.
    // the wrong-css should maybe not be marked as
    // then all not selected are marked as wrong
    questionItemCorrectStateString: function(id) {
      let res = 'notverified'

      if (this.localAnswer.closed){
        if (this.correctAnswerIds.includes(id) && this.answerIds.includes(id)) { // selected and correct
          res = 'correct-sel'
        }else if (!this.correctAnswerIds.includes(id) && ! this.answerIds.includes(id)){ // not selected and not correct
          res = 'correct-impl'
        }else if (!this.correctAnswerIds.includes(id) && this.answerIds.includes(id)){ // selected and not correct
          res = 'wrong-sel'
        }else{ // correct but not selected
          res = 'wrong-impl'
        }

      }

      return res
    },

    // To show which question would be the correct one
    // returns a string, which can be used in CSS
    questionItemRightAnswerString: function(id) {
      let res = 'notverified'

      if (this.localAnswer.closed){
        if (this.correctAnswerIds.includes(id)){
          res = 'correct'
        }else{
          res = 'wrong'
        }
      }

      return res
    },

    answerIdByIndex: function(index) {
      return 'answer-'+(index+1)
    },

    onAnswerChanged(){
      console.log('answer changed - emitting update:answerIds')
      // this updates the answerIds of parent with localAnswerIds
      this.$emit('update:answerIds', this.localAnswerIds)
    },

    // can be used if not clicked on checkbox
    selectAnswer: function(index) {

      if (this.localAnswer.closed){return} // question closed

      console.log('selected answer: '+index)

      if (this.selectionType == 'multiple'){

        if (this.localAnswerIds.includes(index+1)){ // remove it
          let pos = this.localAnswerIds.indexOf(index+1)
          this.localAnswerIds.splice(pos,1)
        }else{ // add answer
          this.localAnswerIds.push(index+1)
        }

      }else{
        this.localAnswerIds[0] = index+1
      }

      console.log(this.localAnswerIds)

      this.onAnswerChanged()
    }
  },

  computed: {

    userSignedIn(){
      return this.$store.getters.userSignedIn
    },

    isAnswerCorrect(){
      console.log('right answers would be: ', this.correctAnswerIds)

      // compare selected and given answers
      let selectedAnswerIds = this.answerIds
      selectedAnswerIds = selectedAnswerIds.sort(function (a, b) {  return a - b;  }); // int sort
      let answeredCorrectly = false

      console.log('correctAnswerIds: '+this.correctAnswerIds)
      console.log('selectedAnswerIds: '+selectedAnswerIds)

      if (areIntArraysEqual(selectedAnswerIds, this.correctAnswerIds)){
        console.log('Question answered correctly')
        answeredCorrectly = true
      }else{
        console.log('Question answered wrong')
        answeredCorrectly = false
      }

      return answeredCorrectly
    },

    correctAnswersInPercent(){
      let intersection = this.correctAnswerIds.filter(value => this.answerIds.includes(value));

      console.log('intersection of correct answers: '+intersection)

      let percentage = 0

      if (typeof intersection !== 'undefined' && typeof this.correctAnswerIds !== 'undefined'){
        percentage =  Math.round( intersection.length / this.correctAnswerIds.length * 100 )
        console.log('correct answers in percent: '+percentage)
      }

      return percentage
    },


    // e.g. 'Network' (not lowercase)
    categoryHumanReadable(){
      let res = ""
      const category = this.category
      if (category != null && this.sectionCatItem != null){
        this.sectionCatItem.sections.forEach( section => {
          if (section.id === category){
            res = section.title
          }
        })
      }
      return res
    },

    difficultyHumanReadable(){
      let res = ""
      if (this.questionItem !== undefined){
        if (this.questionItem.difficulty === 1){ res = "easy"}
        if (this.questionItem.difficulty === 2){ res = "standard"}
        if (this.questionItem.difficulty === 3){ res = "advanced"}
        if (this.questionItem.difficulty === 4){ res = "hard"}
      }

      return res
    },

    // extracts the category from questionId. E.g. 'network'
    category(){
      const path = this.questionId // e.g. question/linux/basics/network/1 holds the category
      const parts = path.split('/');
      return parts[2] // 3. part
    },

    selectionType(){
      let res = 'single'
      let counter = 0;
      // more than 1 possible answer?
      this.questionItem.answers.forEach(answer => {
        if (answer.correct){counter++}
      })
      if (counter>1){res = 'multiple'}
      return res
    }
  },

}

</script>